a{color:#959595; font-size: 15px; text-decoration: none;}
.nav-link b { color: #de2d26; }
a span{color:#de2d26;}
.copyright{text-align: center;}
.welcome h1{font-size: 18px;color: #de2d26;}
.welcome h2{font-size: 35px;color: #211e1d;}
#services{background-color: #e7e7e7;padding: 55px 0;}
.service-footer p{font-size: 15px; text-align: center;margin-bottom: 0;}
.service-body { text-align: center;}
.service-body h3{font-size: 20px;color: #222222;text-align: center;}
.service-body p{font-size: 15px;color: #959595;text-align: center;} 
.service-footer {text-align: center;} 
#contacts table p{font-size: 13px;}